import "./Llmprovidermodalbox.css"
import { useTriggers } from '../../hooks/navigation';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mentorActions } from '../../lib/redux/mentors/slice';
import useApi from '../../hooks/api/base/useApi';
import useLLMs from '../../hooks/llm/useLLMs';
import {getDisplayName} from "./utils"
const Llmprovidermodalbox = props => {
  const auth = useSelector((state) => state.auth.data);
  const mentors = useSelector((state) => state.mentors);
  const dispatch = useDispatch();
  const {handleCloseLLMProviderModal, handleOpenLLMSelectionModal} = useTriggers();
  const [searchInput, setSearchInput] = useState('');
  const [providersLoaded, setProviderLoaded] = useState(false);
  const handleSearchInputUpdate = (event) => {
    setSearchInput(event.target.value);
  }
  const {loading, error} = useLLMs();
  const api = useApi();
  const loadLLMsRaw = async () => {
    const endpoint = `/api/ai-mentor/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/mentor-llms/`;
    const response = await api.get(endpoint, {});
    if (response?.data) {
      dispatch(mentorActions.providersUpdated(response.data));
    }
  };
  const handleChooseProvider = (provider) => {
    dispatch(
      mentorActions.chosenProviderUpdated(provider)
    );
    handleOpenLLMSelectionModal();
  }

  const getImageName = (provider) => {
    switch (provider) {
      case "azure_openai": return "Microsoft_Logo_512px.png";
      case "deepseek": return "deepseek.png";
      case "groq": return "images-5.png";
      case "openai": return "openai-2.svg";
      case "IBLChatNvidia": return "2945060.webp";
      case "perplexity": return "perplexity-ai-icon.webp";
      case "IBLChatAnthropic": return "Claude-AI-KVIXtY-p-500.png";
      case "google": return "google-color-svgrepo-com.svg";
      case "amazon": return "amazon-icon-1024x861-rigpcg3n.png";
      case "xai": return "lIgjSJGU_400x400.jpg";
      default: return provider;
    }
  }
  const isSearchResultEmpty = () => {
    return !!searchInput && !mentors.providers
      .some(
        provider =>
          getDisplayName(provider.name)
            .toLowerCase()
            .includes(searchInput.toLowerCase())
      )
  }
  useEffect(() => {
    if (!loading && !error) {
      setProviderLoaded(true);
      return;
    }
    if (!loading && error) {
      loadLLMsRaw().then(
        () => setProviderLoaded(true)
      )
    }
  }, [loading]);
  return (
    <div className="modal llm-provider-modal"
         >
      <div className="w-layout-vflex modal-container new-resource-container">
        <div className="w-layout-hflex modal-header">
          <h3 className="modal-header-title">LLM Providers</h3>
          <div
            onClick={handleCloseLLMProviderModal}
               data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588" className="modal-close-wrapper"><img
            src="/images/close_1close.png" loading="lazy" alt="Close Button Image" /></div>
        </div>
        <div className="w-layout-vflex modal-body dataset-resource-modal-body">
          <div className="w-layout-hflex flex-block-485">
            <div className="text-block-225">Add knowledge to help your agent provide more relevant insights. Others with
              edit access can reuse these sources for more topics.
            </div>
            <div className="w-layout-hflex flex-block-486">
              <div className="code-embed-24 w-embed">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                     fill="#969696">
                  <path
                    d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z"></path>
                </svg>
              </div>
              <div className="form-block-25 w-form">
                <form id="email-form-17" name="email-form-17" data-name="Email Form 17" method="get"
                      data-wf-page-id="678a7aff3a55326deb175ff9"
                      data-wf-element-id="249fc066-2c04-4639-c8fb-7759d57a6fac" aria-label="Email Form 17"><input
                  className="text-field-14 w-input" maxLength="256" name="search-2" data-name="Search 2"
                  placeholder="Search" value={searchInput} onChange={handleSearchInputUpdate} type="email" id="search-2" required="" /></form>
                <div className="w-form-done" tabIndex="-1" role="region" aria-label="Email Form 17 success">
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div className="w-form-fail" tabIndex="-1" role="region" aria-label="Email Form 17 failure">
                  <div>Oops! Something went wrong while submitting the form.</div>
                </div>
              </div>
            </div>
          </div>
          {
            isSearchResultEmpty() &&
            <div className="w-layout-hflex flex-block-469">
              <div className="text-block-212">
                Sorry, we didn't find any LLM Providers that match your search!
              </div>
            </div>
          }
          <div className="w-layout-grid grid-3">

            {
              providersLoaded && !!mentors?.providers && mentors.providers.map((provider, index) => {
                if (!!searchInput && !getDisplayName(provider.name).toLowerCase().includes(searchInput.toLowerCase())) {
                  return;
                }
                else
                return <div key={`llm-provider-icon-${index}`}
                     data-w-id="249fc066-2c04-4639-c8fb-7759d57a6fb5" className="w-layout-hflex resource-card" onClick={() => handleChooseProvider(provider.name)}><img
                  src={`/images/${getImageName(provider.name)}`} loading="lazy" alt="" className="image-184" />
                  <div>{

                    mentors?.currentMentorLLM?.provider === provider.name ?
                    <strong className="bold-text-29">{getDisplayName(provider.name)}</strong>
                      :
                    getDisplayName(provider.name)
                  }</div>
                </div>
              })

            }
          </div>
          <div className="w-layout-vflex data-resource-upload-box-block"></div>
        </div>
      </div>
    </div>)
}
export default Llmprovidermodalbox;