import React, { useEffect, useState } from 'react';

import { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useUserTimeTracking } from '@iblai/ibl-web-react-common';
import loadScript from 'load-script';
import Home from './home';
import PageLoader from '../loader/page-loader';
import useAnonymousMentor from '../../hooks/iframe/useAnonymousMentor';
import useSetCurrentMentor from '../../hooks/api/mentors/useSetCurrentMentor';
import useGuidedSuggestedPrompts from '../../hooks/chat-room/useGuidedSuggestedPrompts';
import Editmentormodalbox from '../../components/Editmentormodalbox/Editmentormodalbox';
import Creatementormodalbox from '../../components/Creatementormodalbox/Creatementormodalbox';
import useUserAuth from '../../hooks/user/useUserAuth';
import { useDebounce } from '../../hooks/useDebounce';
import { useTriggers } from '../../hooks/navigation';
import { navigationActions } from '../../lib/redux/navigation/slice';
import Apicredentialchangemodalbox from '../../components/Apicredentialchangemodalbox/Apicredentialchangemodalbox';
import Apicredentialaddmodalbox from '../../components/Apicredentialaddmodalbox/Apicredentialaddmodalbox';
import Apicredentialdeletemodalbox from '../../components/Apicredentialdeletemodalbox/Apicredentialdeletemodalbox';
import DatasetResourceTypesModal from '../mentors/edit/dataset-resource/dataset-resource-types-modal';
let onedriveSdkLoadingStarted = false;
export default function HomeWrapper() {
  const auth = useSelector((state) => state.auth.data);
  const sessionId = useSelector((state) => state.chat.sessionId);
  useUserTimeTracking();

  const mentors = useSelector((state) => state.mentors);
  const { setupUserAuth } = useUserAuth();
  const dispatch = useDispatch();
  const anonymous = mentors.mentor?.settings?.allow_anonymous;

  const [startMentorSetup, setStartMentorSetup] = useState(false);
  const { loading } = useSetCurrentMentor(startMentorSetup);
  const anonymousMentor = useAnonymousMentor();

  const { modals } = useSelector((state) => state.navigation.data);
  const { loadGuidedPrompts } = useGuidedSuggestedPrompts();

  const location = useLocation();
  const chatExportData = useSelector(
    (state) => state.navigation.chatHistoryExportData
  );
  const isOnedriveReady = useCallback(() => !!window.OneDrive, []);
  const onedriveSdkUrl = 'https://js.live.net/v7.2/OneDrive.js';
  useEffect(() => {
    if (!isOnedriveReady() && !onedriveSdkLoadingStarted) {
      onedriveSdkLoadingStarted = true;
      loadScript(onedriveSdkUrl, () => {
        console.log('onedrive sdk ready');
      });
    } else if (isOnedriveReady()) {
      console.log('onedrive sdk ready');
    }
  }, [isOnedriveReady]);

  const {
    handleTriggerChatHistoryExportDownload,
    handleUpdateChatHistoryStatus,
  } = useTriggers();

  useEffect(() => {
    if (!anonymous && auth.user && auth.tenant) {
      setStartMentorSetup(true);
    }
  }, [auth]);

  useEffect(() => {
    setupUserAuth();
  }, []);

  useDebounce(
    () => {
      if (!chatExportData) {
        return;
      }
      if (
        chatExportData?.state === 'pending' ||
        chatExportData?.status?.state === 'processing'
      ) {
        handleUpdateChatHistoryStatus();
      } else if (
        chatExportData?.state === 'completed' ||
        chatExportData?.status?.state === 'completed'
      ) {
        handleTriggerChatHistoryExportDownload();
      } else {
        dispatch(navigationActions.chatHistoryExportDataUpdated(null));
      }
    },
    2500,
    [chatExportData]
  );

  return (
    <>
      {(location?.pathname.includes('/share/chat') ||
        ((auth?.user || anonymous) && mentors?.mentor && auth?.tenant)) && (
        <Home />
      )}{' '}
      {(loading || anonymousMentor?.loading) && <PageLoader />}
      {modals?.['mentor-creation-modal'] && !mentors?.mentor && (
        <Creatementormodalbox />
      )}
      {modals?.['edit-mentor-modal'] && mentors?.mentors?.count === 0 && (
        <Editmentormodalbox />
      )}

      <Toaster
        toastOptions={{
          className: 'site-toaster-container',
        }}
        containerStyle={{
          zIndex: 2147483647,
        }}
      />
    </>
  );
}
