import React from 'react';
import './Apicredentialaddmodalbox.css';
import useDatasetResourceAdd from '../../hooks/datasets/useDatasetResourceAdd';
import SubmitBtn from '../SubmitBtn/SubmitBtn';
import { useTranslation } from 'react-i18next';
import { useTriggers } from '../../hooks/navigation';
import { useSelector } from 'react-redux';
import useApi from '../../hooks/api/base/useApi';

const Apicredentialaddmodalbox = (props) => {
  const auth = useSelector((state) => state.auth.data);
  const {currentApiKeyOrg} = useSelector((state) => state.navigation);
  const {handleCloseAddAPICredentialModal, handleApiKeyStatusUpdated, handleNewlyCreatedApiKeyUpdated} = useTriggers();
  const { t } = useTranslation();
  const [apiKeyInput, setApiKeyInput] = React.useState(
    {name: "", key: "", expiration_date: ""}
  );
  const handleUpdateApiKeyName = (event) => {
    const value = event.target.value;
    setApiKeyInput({...apiKeyInput, name: value});
  }


  const handleUpdateApiKeyExpirationDate = (event) => {
    const value = event.target.value;
    setApiKeyInput({...apiKeyInput, expiration_date: value});
  }
  function daysFromCurrentDate(targetDate) {
    if (!targetDate) return null;
    const currentDate = new Date();
    const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
    const timeDifference = targetDate.getTime() - currentDate.getTime();
    const daysDifference = Math.round(timeDifference / oneDay);
    return daysDifference * 24 * 3600;
  }
  const api = useApi();
  const apiKeyUrl = "/api/core/platform/api-tokens/";
  const addApiKey = () => {
    const apiKey = {
      name: apiKeyInput.name,
      platform_key: currentApiKeyOrg,
      expires_in: daysFromCurrentDate(new Date(apiKeyInput.expiration_date)),
    }
    api.post(apiKeyUrl, apiKey, false, localStorage.getItem("dm_token")).then(
      (response) => {
        if (response?.data) {
          handleNewlyCreatedApiKeyUpdated(response.data.name, response.data.key);
          handleCloseAddAPICredentialModal()
          handleApiKeyStatusUpdated()
        }
      },
    )
  }
  return (
    <div className="modal add-api-credential-modal">
      <div className="w-layout-vflex modal-container create-secret-key-modal-container dataset-url-resource-modal-container">
        <div className="w-layout-hflex modal-header">
          <h3 className="modal-header-title">{t('Add API Key')}</h3>
          <div
            className="modal-close-wrapper"
            data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
            onClick={handleCloseAddAPICredentialModal}
          >
            <img alt="" loading="lazy" src="/images/close_1close.png" />
          </div>
        </div>
        <div className="w-layout-vflex modal-body create-secret-key-modal-body">
          <div className="secret-key-form-block w-form">
            <form
              className="manage-account-form secret-key-form"
              data-name="Email Form 2"
              data-wf-element-id="1860f14d-8ea7-daca-eba5-62232122c044"
              data-wf-page-id="650305782bc61751e5765214"
              id="email-form-2"
              method="get"
              name="email-form-2"
            >
              <div className="w-layout-vflex input-block invite-user-secret-block">
                <div className="w-layout-hflex flex-block-19">
                  <label className="form-input-label" htmlFor="name-6">
                    {t('Key Name (*required)')}
                  </label>
                </div>
                <input
                  className="form-input secret-key-form-input w-input"
                  custom-value="Personal"
                  data-name="Organisation Name 4"
                  id="organisation-name-4"
                  maxLength={256}
                  name="organisation-name-4"
                  placeholder="main-key"
                  onChange={handleUpdateApiKeyName}
                  type="text"
                  value={apiKeyInput.name}
                  required
                />
              </div>
              <div className="w-layout-hflex flex-block-19">
                <label className="form-input-label" htmlFor="name-6">
                  {t('Expiration Date')}
                </label>
              </div>
              <input
                className="form-input secret-key-form-input w-input"
                custom-value="Personal"
                data-name="Organisation Name 4"
                id="organisation-name-4"
                maxLength={256}
                name="organisation-name-4"
                placeholder=''
                onChange={handleUpdateApiKeyExpirationDate}
                value={apiKeyInput.expiration_date}
                type="date"
              />
          <div className="w-layout-hflex flex-block-20">
            <SubmitBtn
              data-w-id="1860f14d-8ea7-daca-eba5-62232122c04b"
              href="#"
              onClick={addApiKey}
              label={'Submit'}
            />
          </div>
        </form>
      </div>
    </div>
</div>
</div>
)
  ;
};

export default Apicredentialaddmodalbox;
