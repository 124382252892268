import useApi from '../base/useApi';
import { useSelector } from 'react-redux';
import { userIsOnTrial } from '../../../utils/shared';

export default function useMentorDetails() {
  const auth = useSelector((state) => state.auth.data);
  const api = useApi();

  const loadMentorDetails = async (tenant, slug) => {
    const url = `/api/search/ai-search/?tenant="${tenant}"&facet=["${slug}"]`;
    const response = await api.get(url);
    if (response?.data) {
      return response.data;
    }
  };

  const loadMentorSettings = async (tenant, unique_id) => {
    if (userIsOnTrial()) {
      return;
    }
    const url = `/api/ai-mentor/orgs/${tenant}/users/${auth?.user.user_nicename}/mentors/${unique_id}/settings/`;
    const response = await api.get(url);
    if (response?.data) {
      return response.data;
    }
  };

  return { loadMentorDetails, loadMentorSettings };
}
