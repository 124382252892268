import useLLMs from '../../../hooks/llm/useLLMs';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import useLLMItem from '../../../hooks/llm/useLLMItem';
import useSelectMentorLLM from '../../../hooks/llm/useSelectLLM';
import { mentorActions } from '../../../lib/redux/mentors/slice';
import { toast } from 'react-hot-toast';
import { useDebounce } from '../../../hooks/useDebounce';
import {
  canSwitchLLm,
  handleLayoutsWithCustomTooltipsJS,
} from '../../../utils/shared';
import useNewChat from '../../../hooks/chat-room/useNewChat';
import { useTranslation } from 'react-i18next';
import { useTriggers } from '../../../hooks/navigation';

const getImgClass = (name, provider) => {
  if (name?.includes('gpt') || name?.startsWith('o1-')) {
    return '';
  } else if (name?.includes('mixtral')) {
    return 'mixtral-ai';
  } else if (provider?.includes('google') || name?.includes('gemma')) {
    return 'gemini-1-5-pro';
  } else if (name?.includes('llama')) {
    return 'llama-2';
  } else if (name?.includes('claude-')) {
    return 'claude-ai';
  } else {
    return 'imagen-2';
  }
};

function LLMItem({ llm, setShowLlmMenu }) {
  const { handleLLMUpdateKeyBtnClick, handleLLMViewBtnClick } = useLLMItem(llm);
  const { handleLLMSelected } = useSelectMentorLLM(llm);
  const dispatch = useDispatch();
  const { handleNewChatBtnClick } = useNewChat();
  const { t } = useTranslation();

  const handleSelectLLM = async () => {
    await handleLLMSelected(true);
    dispatch(mentorActions.currentMentorLLMUpdated(llm));
    setShowLlmMenu(false);
    toast.success(
      t(`{{displayName}} has been set as the current LLM.`, {
        displayName: llm?.display_name,
      })
    );
    handleNewChatBtnClick();
  };

  useDebounce(handleLayoutsWithCustomTooltipsJS, 200, []);

  return (
    <div
      className="w-layout-hflex llm-dropdown-element greyish-bg-in-darkmode"
      style={{ cursor: canSwitchLLm(llm) ? 'pointer' : 'not-allowed' }}
    >
      <div
        className="w-layout-hflex llm-dropdown-details-block"
        style={{ width: '100%' }}
        onClick={canSwitchLLm(llm) ? handleSelectLLM : () => {}}
      >
        <div
          className={`llm-icon-block ${getImgClass(llm.model, llm.name)}`}
        ></div>
        <div className="llm-dropdown-element-title">{llm.display_name}</div>
      </div>
      <div className="w-layout-hflex llm-dropdown-action-block">
        {canSwitchLLm(llm) ? (
          <div className="w-layout-hflex llm-key-status active">
            <div className="llm-key-status-text">{t('Valid Key')}</div>
          </div>
        ) : (
          <div className="w-layout-hflex llm-key-status">
            <div className="llm-key-status-text">{t('Missing Key')}</div>
          </div>
        )}

        <div
          behave-as-btn="1"
          tabIndex="99"
          role="button"
          data-w-id="d93c89e8-955f-1442-4073-6de02066b925"
          className="user-input-actions-container greyish-bg-in-darkmode has-custom-tooltip"
          onClick={handleLLMViewBtnClick}
        >
          <img
            loading="lazy"
            height="Auto"
            alt="Voice message icon"
            src="https://cdn.prod.website-files.com/66953e194f60e4f88496390d/66953e194f60e4f88496393a_eye%20(1).svg"
            className="image-6 whiten-in-dark-mode llm-dropdown-element-icon"
          />
          <div className="tooltip tooltip-microphone llm-tooltip view-details-tooltip">
            {t('View Details')}
          </div>
        </div>
        <div
          behave-as-btn="1"
          tabIndex="99"
          role="button"
          data-w-id="d93c89e8-955f-1442-4073-6de02066b929"
          className="user-input-actions-container greyish-bg-in-darkmode has-custom-tooltip"
          onClick={handleLLMUpdateKeyBtnClick}
        >
          <img
            loading="lazy"
            height="Auto"
            alt="Voice message icon"
            src="https://cdn.prod.website-files.com/66953e194f60e4f88496390d/66953e194f60e4f884963953_settings.svg"
            className="image-6 whiten-in-dark-mode llm-dropdown-element-icon"
          />
          <div className="tooltip tooltip-microphone llm-tooltip update-key-tooltip">
            {t('Update Key')}
          </div>
        </div>
      </div>
    </div>
  );
}

export default function ChatLLMs() {
  const { loading, error } = useLLMs();
  const mentors = useSelector((state) => state.mentors);
  const llmMenuRef = useRef(null);
  const llmOptionsRef = useRef(null);
  const [showLlmMenu, setShowLlmMenu] = useState(false);
  const { t } = useTranslation();
  const {handleOpenLLMProviderModal} = useTriggers();

  const handleClickOutsideMenu = (event) => {
    if (
      showLlmMenu &&
      llmMenuRef.current &&
      !llmMenuRef.current.contains(event.target) &&
      !llmOptionsRef.current.contains(event.target)
    ) {
      setShowLlmMenu(false);
    }
  };

  const getLLMFromName = (name, provider) => {
    if (!provider) {
      return mentors?.llms?.find((llm) => llm.model === name);
    } else {
      return mentors?.llms?.find((llm) => llm.model === name && llm.provider === provider);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideMenu);

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideMenu);
    };
  }, [handleClickOutsideMenu]);

  const currentMentorLLM =
    mentors?.currentMentorLLM || getLLMFromName(mentors?.mentor?.llm_name, mentors?.mentor?.llm_provider);

  return (
    <div
      data-hover="false"
      data-delay="0"
      className="llm-dropdown-block w-dropdown"
      style={{ zIndex: 901, width: '160px' }}
    >
      {currentMentorLLM && (
        <div
          className="llm-dropdown-toggle w-dropdown-toggle w--open"
          id="w-dropdown-toggle-10"
          aria-controls="w-dropdown-list-10"
          aria-haspopup="menu"
          aria-expanded="true"
          role="button"
          onClick={() => {
            handleOpenLLMProviderModal();
            // setShowLlmMenu(!showLlmMenu);
          }}
          tabIndex="0"
          ref={llmMenuRef}
        >
          <div className="w-layout-hflex selected-llm-block">
            <div
              className={`llm-icon-block ${getImgClass(currentMentorLLM?.model, currentMentorLLM?.name)}`}
            ></div>
            <div className="selected-llm-label whiten-in-dark-mode">
              {currentMentorLLM?.display_name}
            </div>
            <img
              src="/images/chevron-down-3.svg"
              loading="lazy"
              width="18"
              alt="View your profile dropdown icon in white mode"
              className="whiten-in-dark-mode chevron-img llm-dropdown-icon"
            />
          </div>
        </div>
      )}
      {showLlmMenu && (
        <nav
          className="llm-dropdown-list black-bg-dark-mode all-whiten-in-dark-mode whiten-border-in-dark-mode w-dropdown-list w--open"
          id="w-dropdown-list-10"
          ref={llmOptionsRef}
          aria-labelledby="w-dropdown-toggle-10"
        >
          <div className="w-layout-vflex llm-dropdown-list-content">
            <div className="w-layout-hflex flex-block-94">
              <h5 className="heading-26">{t('Select LLM')}</h5>
            </div>
            <div className="w-layout-vflex llm-dropdown-grid-list">
              {mentors?.llms?.map((llm, index) => (
                <LLMItem
                  llm={llm}
                  key={llm.model}
                  setShowLlmMenu={setShowLlmMenu}
                />
              ))}
            </div>
          </div>
        </nav>
      )}
    </div>
  );
}
