import './Apicredentialchangemodalbox.css';
import SubmitBtn from '../SubmitBtn/SubmitBtn';
import { useTranslation } from 'react-i18next';
import {useTriggers} from '../../hooks/navigation';
import { useSelector } from 'react-redux';
import useApi from '../../hooks/api/base/useApi';
import {useState} from 'react';

const Apicredentialchangemodalbox = (props) => {
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth.data);
  const {handleCloseChangeAPICredentialModal, handleApiKeyStatusUpdated} = useTriggers();
  const {currentApiKeyOrg} = useSelector((state) => state.navigation);
  const { currentApiKey } = useSelector((state) => state.navigation);
  const [currentApiKeyValue, setCurrentApiKeyValue] = useState(JSON.stringify(currentApiKey.value));
  const updateCurrentApiKeyValue = (event) => {
    const value = event.target.value;
    setCurrentApiKeyValue(value);
  }
  const api = useApi();
  const apiKeyUrl = `/api/core/platform/api-tokens/`;
  const setApiKey = () => {
    const apiKey = {
      "name": currentApiKey.name,
      "value": JSON.parse(currentApiKeyValue),
      "platform": auth?.tenant?.key
    }
    api.patch(apiKeyUrl, apiKey).then(
      () => {
        handleCloseChangeAPICredentialModal()
        handleApiKeyStatusUpdated()
      }
    )
  }
  return (
    <div className="modal change-api-credential-modal">
      <div className="w-layout-vflex modal-container">
        <div className="w-layout-hflex modal-header">
          <h3 className="modal-header-title">{t('Change API Credential')}</h3>
          <div
            className="modal-close-wrapper"
            data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
            onClick={handleCloseChangeAPICredentialModal}
          >
            <img alt="" loading="lazy" src="/images/close_1close.png" />
          </div>
        </div>
        <div className="w-layout-vflex modal-body create-secret-key-modal-body">
          <div className="secret-key-form-block w-form">
            <form
              className="manage-account-form secret-key-form"
              data-name="Email Form 2"
              data-wf-element-id="a4448386-1907-4997-aafd-377c8db2cd87"
              data-wf-page-id="650305782bc6175fe5765214"
              id="email-form-2"
              method="get"
              name="email-form-2"
            >
              <div className="w-layout-vflex input-block invite-user-secret-block">
                <div className="w-layout-hflex flex-block-19">
                  <label className="form-input-label" htmlFor="name-6">
                    {currentApiKey.name}
                  </label>
                </div>
                <input
                  className="form-input secret-key-form-input w-input"
                  custom-value="Personal"
                  data-name="Organisation Name 4"
                  id="organisation-name-4"
                  maxLength={256}
                  name="organisation-name-4"
                  placeholder={JSON.stringify(currentApiKey.value)}
                  value={currentApiKeyValue}
                  onChange={updateCurrentApiKeyValue}
                  type="text"

                />
              </div>
              <div className="w-layout-hflex flex-block-20">
                <SubmitBtn
                  data-w-id="1860f14d-8ea7-daca-eba5-62232122c04b"
                  href="#"
                  onClick={setApiKey}
                  label={'Submit'}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Apicredentialchangemodalbox;
