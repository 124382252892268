import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export default function useEditMentorTabs() {
  const edit = useSelector((state) => state.mentors.edit);
  const auth = useSelector((state) => state.auth.data);
  const [tab, setTab] = useState(edit?.activeTab || 'settings');
  const editMentor = useSelector((state) => state.mentors.edit.mentor);
  const { t } = useTranslation();
  const allTabs = [
    { key: 'settings', title: t('Settings') },
    { key: 'llm', title: t('LLM') },
    { key: 'prompts', title: t('Prompts') },
    { key: 'tools', title: t('Tools') },
    { key: 'safety', title: t('Safety') },
    { key: 'flow', title: t('Flow') },
    { key: 'history', title: t('History') },
    { key: 'datasets', title: t('Datasets') },
    { key: 'api', title: t("API")},
    { key: 'embed', title: t('Embed') },
  ];

  const disabledTabsStr = process.env.REACT_APP_IBL_MENTOR_DISABLED_TABS || '';
  const LANGFLOW_BASE_URL = process.env.REACT_APP_IBL_LANGFLOW_APP_BASE_URL;
  const LANGFLOW_APP_IS_ENABLED = process.env.REACT_APP_IBL_ENABLE_LANGFLOW_APP;
  const disabledTabs =
    disabledTabsStr?.split(',')?.map((tab) => tab?.trim().toLowerCase()) || [];
  //REMOVE FLOW TAB WHEN NOT ADMIN OR LANGFLOW VARs IN ENV NOT SET ACCORDINGLY
  if (
    !auth?.tenant?.is_admin ||
    !LANGFLOW_BASE_URL ||
    LANGFLOW_APP_IS_ENABLED !== 'true'
  ) {
    disabledTabs.push('flow');
  }

  const tabs = allTabs.filter((item) => !disabledTabs?.includes(item.key));

  return { tab, setTab, tabs };
}
