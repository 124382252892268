import { useDispatch } from 'react-redux';

export function useAnalytics() {
  const dispatch = useDispatch();

  function updateAnalyticsMentor(payload) {
    dispatch({
      type: 'analytics/mentorUpdated',
      payload,
    });
  }

  return {
    updateAnalyticsMentor,
  };
}
