const getDisplayName = (provider) => {
  switch (provider) {
    case "azure_openai": return "Microsoft";
    case "deepseek": return "DeepSeek";
    case "groq": return "Groq";
    case "openai": return "OpenAI";
    case "IBLChatNvidia": return "NVIDIA";
    case "perplexity": return "Perplexity";
    case "IBLChatAnthropic": return "Anthropic";
    case "google": return "Google";
    case "amazon": return "Amazon";
    case "xai": return "xAI";
    default: return provider;
  }
}
module.exports = {
  getDisplayName,
}