import React from 'react';

const initializeLocalStorageWithObject = (data) => {
  Object.entries(data).forEach(([key, value]) => {
    localStorage.setItem(key, value);
  });
};

export const ProductCustomSSOLogin = () => {
  React.useEffect(() => {
    const queryParam = new URLSearchParams(window.location.search);
    if (queryParam.get('data')) {
      initializeLocalStorageWithObject(JSON.parse(queryParam.get('data')));
      /*const redirectPath = localStorage.getItem('redirect-path')
        ? localStorage.getItem('redirect-path')
        : '/';
      localStorage.removeItem('redirect-path');*/
      const actualPathname = window.location.pathname;
      const targetURL = actualPathname.endsWith('/sso-login')
        ? actualPathname.slice(0, -'/sso-login'.length)
        : actualPathname;
      window.location.href = targetURL;
    }
  }, []);
};
