import { IBLUserProfile } from '@iblai/ibl-web-react-common';
import { useSelector } from 'react-redux';
import { useEffect, useState, Suspense } from 'react';

const defaultUIColors = {
  color: '#000',
  backgroundColor: '#FFF',
  hoverColor: '#ededed',
  darkMode: false,
};

export default function Profile() {
  const darkMode = useSelector((state) => state.navigation?.darkMode);
  const [UIcolor, setUIColor] = useState(defaultUIColors);

  useEffect(() => {
    if (darkMode) {
      setUIColor({
        color: '#FFF',
        backgroundColor: '#000',
        hoverColor: '#3e3e3e',
        darkMode: true,
      });
    } else {
      setUIColor(defaultUIColors);
    }
  }, [darkMode]);
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <IBLUserProfile showProfileMenu={false} {...UIcolor} />
    </Suspense>
  );
}
