import { useEffect } from 'react';

import { useTriggers } from '../../hooks/navigation';

export default function MentorsOptions() {
  const { handleOpenEditMentorModal } = useTriggers();

  useEffect(() => {
    handleOpenEditMentorModal();
  }, []);

  return null;
}
