import { useDispatch, useSelector } from 'react-redux';
import { chatActions } from '../../lib/redux/chat/slice';
import useSuggestedPrompt from './useSuggestedPrompt';
import useApi from '../api/base/useApi';
import { useEffect } from 'react';
import usePreviewMode from '../iframe/usePreviewMode';
import { getTabsAndMessages, tabs } from '../../modules/platform/data';
import { navigationActions } from '../../lib/redux/navigation/slice';

export default function useSetChatSession() {
  const auth = useSelector((state) => state.auth.data);
  const mentors = useSelector((state) => state.mentors);
  const chat = useSelector((state) => state.chat);
  const tabbedMessages = useSelector((state) => state.chat.tabbedMessages);
  const { data } = useSelector((state) => state.navigation);
  const messages = useSelector((state) => state.chat.messages);
  const sessionId = useSelector((state) => state.chat.sessionId);
  const session = useSelector((state) => state.chat.session);
  const { handleSuggestedPromptSelection } = useSuggestedPrompt();
  const { mode, advanceActiveTab } = useSelector((state) => state.embed);
  const embedPreview = usePreviewMode();
  const api = useApi();
  const dispatch = useDispatch();

  const createChatSession = async (resetMessages = true) => {
    if (!mentors?.mentor?.name) {
      return;
    }

    const endpoint = `/api/ai-mentor/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/sessions/?seq=${chat?.sequence}`;
    const payload = {
      mentor: mentors?.mentor?.name,
    };

    const response = await api.post(endpoint, payload);

    if (response?.data) {
      if (mode === 'advanced' && advanceActiveTab) {
        dispatch(
          chatActions.tabbedSessionsUpdated({
            ...chat.tabbedSessions,
            [advanceActiveTab]: response?.data.session_id,
          })
        );
        // dispatch(
        //   chatActions.tabbedMessagesUpdated({
        //     ...tabbedMessages,
        //     [advanceActiveTab]: tabs
        //       .find((tab) => tab.name === advanceActiveTab)
        //       .prompts.map((prompt) => ({
        //         hide: prompt.hide || false,
        //         ...prompt,
        //       })),
        //   })
        // );
        dispatch(
          navigationActions.navigationUpdated({
            ...data,
            mobileSidebar: false,
          })
        );
      } else {
        dispatch(chatActions.sessionUpdated(response?.data.session_id));
        dispatch(
          chatActions.sessionDataUpdated({
            sessionId: response?.data.session_id,
            mentor: mentors?.mentor?.unique_id,
          })
        );
        dispatch(chatActions.vectorDocumentsUpdated(null));
      }
      if (resetMessages) {
        if (mode === 'advanced') {
          // dispatch(
          //   chatActions.tabbedMessagesUpdated({
          //     ...chat.tabbedMessages,
          //     [embed.advanceActiveTab]: getTabsAndMessages(),
          //   })
          // );
        } else {
          dispatch(chatActions.messagesUpdated([]));
        }
      }
    }
  };

  return { createChatSession };
}
