import { useSelector } from 'react-redux';
import useNewChat from '../../hooks/chat-room/useNewChat';

export default function CollapsedSidebarNewChat() {
  const { handleNewChatBtnClick } = useNewChat();
  const { mode } = useSelector((state) => state.embed);
  return (
    <div
      className="w-layout-hflex new-chat-btn-sidebar-container"
      style={{ display: mode === 'advanced' ? 'none' : 'flex' }}
    >
      <div
        behave-as-btn="1"
        prop-events-names="onClick"
        prop-events-value-onclick="handleNewChatBtnClick"
        title="New Chat"
        tabIndex="101"
        role="button"
        onClick={handleNewChatBtnClick}
        className="-layout-hflex new-chat-btn-block all-whiten-in-dark-mode greyish-bg-in-darkmode new-chat-btn-block-small-block light-grey-bg-dark-mode"
      >
        <img
          src="/images/plus.svg"
          loading="lazy"
          alt="Create New Chat By Clicking on New Chat Button"
          className="image-32"
        />
      </div>
    </div>
  );
}
