import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { mentorActions } from '../../../lib/redux/mentors/slice';
import useApi from '../base/useApi';
import useUpdateMentorSettings from './useUpdateMentorSettings';
import { useTriggers } from '../../navigation';
import useMentorUtils from '../../utils/useMentorUtils';
import { endpoints } from '../../../modules/mentors-overview/endpoints';
import { useDebounce } from 'use-debounce';

const useMentors = (featured = false, start = true) => {
  const auth = useSelector((state) => state.auth.data);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [hasLoadedOnce, setHasLoadedOnce] = useState(false);
  const [error, setError] = useState('');
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({
    limit: featured ? 8 : 7,
    offset: 0,
    order_by: 'created_at',
  });

  const [query] = useDebounce(search, 1000);

  const mentors = useSelector((state) => state.mentors);
  const api = useApi();
  const { handleUpdateSettings } = useUpdateMentorSettings();
  const { handleOpenEditMentorModal } = useTriggers();
  const { updateLocalMentor } = useMentorUtils();

  const loadMentors = async () => {
    if (!auth?.user?.user_nicename || !auth?.tenant?.key) {
      return;
    }
    const params = { ...filters, query };
    if (featured) params['featured'] = true;

    setLoading(true);
    try {
      const response = await api.get(
        endpoints.getMentorsOverview({
          org: auth?.tenant?.key,
          userId: auth?.user?.user_nicename,
        }),
        params
      );

      if (response?.data) {
        const { results, count, current_page, total_pages, facets } =
          response.data;

        if (featured) {
          dispatch(
            mentorActions.featuredMentorsUpdated({
              results,
              count,
              currentPage: current_page,
              totalPages: total_pages,
              facets,
            })
          );
        } else {
          dispatch(
            mentorActions.mentorsUpdated({
              results,
              count,
              currentPage: current_page,
              totalPages: total_pages,
              facets,
            })
          );
        }
      } else {
        setError(response?.error || 'Failed to load mentors');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setHasLoadedOnce(true);
      setLoading(false);
    }
  };

  const seedDefaultMentors = async () => {
    try {
      const url = `/api/ai-mentor/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/mentor/seed/`;
      const response = await api.get(url);
      return response?.data;
    } catch (err) {
      setError(err.message);
      return null;
    }
  };

  const handleSetPage = (newPage) => {
    setFilters((prev) => ({
      ...prev,
      offset: (newPage - 1) * prev.limit,
    }));
  };

  const handleMentorItemSelect = (event, mentor) => {
    event.preventDefault();
    dispatch(mentorActions.editMentorUpdated(mentor));
    handleOpenEditMentorModal();
  };

  const handleMentorItemSelectFromOptions = (mentor) => {
    dispatch(mentorActions.editMentorUpdated(mentor));
  };

  const addMentorToFeatured = (mentor) => {
    const currentResults = mentors?.featured?.results || [];
    const newFeaturedMentors = {
      ...mentors.featured,
      results: [...currentResults, mentor],
      count: (mentors?.featured?.count || 0) + 1,
    };
    dispatch(mentorActions.featuredMentorsUpdated(newFeaturedMentors));
  };

  const removeMentorFromFeatured = (mentor) => {
    const currentResults = mentors?.featured?.results || [];
    const newResults = currentResults.filter(
      (item) => item.unique_id !== mentor.unique_id
    );

    dispatch(
      mentorActions.featuredMentorsUpdated({
        ...mentors.featured,
        results: newResults,
        count: (mentors?.featured?.count || 0) - 1,
      })
    );
  };

  const handleFeaturedStateChanged = async (mentor, status) => {
    const visibility = status
      ? 'viewable_by_tenant_students'
      : 'viewable_by_tenant_admins';

    try {
      const payload = {
        mentor_visibility: visibility,
        featured: status,
      };
      await handleUpdateSettings(mentor, payload);
      const featuredMentor = {
        ...mentor,
        settings: { ...mentor.settings, mentor_visibility: visibility },
      };
      updateLocalMentor(featuredMentor);

      if (status === true) {
        addMentorToFeatured(mentor);
      } else {
        removeMentorFromFeatured(mentor);
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const getFilteredMentors = () => {
    const mentorsList = featured
      ? mentors?.featured?.results
      : mentors?.mentors?.results;
    if (!mentorsList) return [];

    return search
      ? mentorsList.filter((item) =>
          item.name?.toLowerCase().includes(search?.toLowerCase())
        )
      : mentorsList;
  };

  const loadMentorCategoriesList = async () => {
    try {
      const url = `/api/ai-mentor/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/mentor/categories`;
      const response = await api.get(url);
      if (response?.data && Array.isArray(response?.data)) {
        dispatch(mentorActions.mentorsCategoriesUpdated(response?.data));
      }
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    if (start && auth?.tenant?.key && auth?.user?.user_nicename) {
      loadMentorCategoriesList();
    }
  }, [auth?.tenant?.key, auth?.user?.user_nicename]);

  useEffect(() => {
    setFilters((prev) => ({ ...prev, offset: 0 }));
  }, [query]);

  useEffect(() => {
    if (hasLoadedOnce) {
      loadMentors();
    }
  }, [filters]);

  useEffect(() => {
    if ((featured && mentors?.featured) || (!featured && mentors?.mentors)) {
      setHasLoadedOnce(true);
      return;
    }
    if (
      start &&
      auth?.tenant?.key &&
      auth?.user?.user_nicename &&
      !hasLoadedOnce
    ) {
      loadMentors();
    }
  }, [start, auth?.tenant?.key, auth?.user?.user_nicename]);

  const currentData = featured ? mentors?.featured : mentors?.mentors;

  return {
    loading,
    hasLoadedOnce,
    error,
    page: currentData?.currentPage || 1,
    numPages: currentData?.totalPages || 1,
    handleSetPage,
    handleSearchChange,
    handleFeaturedStateChanged,
    mentorsToDisplay: getFilteredMentors(),
    handleMentorItemSelect,
    seedDefaultMentors,
    totalCount: currentData?.count || 0,
    facets: currentData?.facets || {},
    handleMentorItemSelectFromOptions,
  };
};

export default useMentors;
