import { areDatesFromSameDay, dayFormat, timeFormat } from './utils';

export const transformResponse = {
  getConversationVolume: (response) => {
    const allDates = response.map((item) => item.date);
    const isSameDay = areDatesFromSameDay(allDates);

    return response.map((item) => ({
      period: isSameDay
        ? timeFormat(new Date(item.date))
        : dayFormat(new Date(item.date)),
      conversationCount: item.conversation_count,
    }));
  },

  getRegisteredUsers: (response) => {
    const allDates = response.map((item) => item.interval);
    const isSameDay = areDatesFromSameDay(allDates);

    return response.map((item) => ({
      period: isSameDay
        ? timeFormat(new Date(item.interval))
        : dayFormat(new Date(item.interval)),
      count: item.count,
    }));
  },

  getUserRatings: (response) => {
    const allDates = response.map((item) => item.period);
    const isSameDay = areDatesFromSameDay(allDates);

    return response.map((item) => ({
      period: isSameDay
        ? timeFormat(new Date(item.period))
        : dayFormat(new Date(item.period)),
      feedbackCount: item.feedback_count,
    }));
  },

  getTopicsAnalytics: (response) =>
    response.map((item) => ({
      topicName: item.topic_name,
      conversationCount: item.conversation_count,
    })),

  getAverageMessagesPerSession: (response) => {
    const allDates = response.map((item) => item.time);
    const isSameDay = areDatesFromSameDay(allDates);

    return response.map((item) => ({
      period: isSameDay
        ? timeFormat(new Date(item.time))
        : dayFormat(new Date(item.time)),
      averageMessages: item.avg_messages,
      totalSessions: item.total_sessions,
    }));
  },

  getUserSentiment: (response) => [
    {
      name: 'Positive',
      value: response.sentiment_distribution.positive,
      color: '#E3F2FD',
    },
    {
      name: 'Neutral',
      value: response.sentiment_distribution.neutral,
      color: '#90CAF9',
    },
    {
      name: 'Negative',
      value: response.sentiment_distribution.negative,
      color: '#42A5F5',
    },
  ],

  getUserCohortsOverTime: (response) => {
    const allDates = response.periods;
    const isSameDay = areDatesFromSameDay(allDates);

    return response.periods.map((date, index) => ({
      day: isSameDay ? timeFormat(new Date(date)) : dayFormat(new Date(date)),
      new: response.new_users[index],
      veteran: response.veteran_users[index],
    }));
  },

  getUserMetricsPie: (response) => [
    {
      name: 'newUsers',
      value: response.new_users.count,
      fill: '#E3F2FD',
    },
    {
      name: 'veteranUsers',
      value: response.returning_users.count,
      fill: '#90CAF9',
    },
  ],

  getUniqueUsers: (response) => {
    const allDates = response.map((item) => item.period);
    const isSameDay = areDatesFromSameDay(allDates);

    return response.map((item) => ({
      period: isSameDay
        ? timeFormat(new Date(item.period))
        : dayFormat(new Date(item.period)),
      userCount: item.user_count,
    }));
  },

  getMostEngagedUsers: (response) =>
    response.map((item) => ({
      username: item.username,
      chatMessageCount: item.chat_message_count,
    })),

  getTopTopics: (response) =>
    response.map((item) => ({
      name: item.name,
      conversationCount: item.conversation_count,
    })),
};
