import React, { useEffect } from 'react';

import $ from 'jquery';
import classnames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// import ThemeSwitcher from './theme-switcher';
import { useHeader } from '../../hooks/header/useHeader';
import Helpmenudropdownbox from '../../components/Helpmenudropdownbox/Helpmenudropdownbox';

const SidebarBottomBlock = () => {
  const auth = useSelector((state) => state.auth.data);
  const mentors = useSelector((state) => state.mentors);
  const { sidebar } = useSelector((state) => state.navigation.data);
  const location = useLocation();
  const pathname = location.pathname;

  const platform = auth?.tenant?.key;
  const mentor = mentors?.mentor?.slug;
  const baseUrl = `/platform/${platform}/${mentor}`;
  const analyticsUrl = `${baseUrl}/analytics`;
  let instructorMode = useSelector((state) => state.navigation.instructorMode);
  if (!auth?.tenant?.is_admin) {
    instructorMode = false;
  }

  const { HELP_THREE_DOT_MENU_ENABLED } = useHeader();
  const { t } = useTranslation();

  const [isHovered, setIsHovered] = React.useState(false);

  const handleJQueryScripts = () => {
    $(document).on('click', '.help-action-btn', function () {
      $(this)
        .closest('.header-help-dropdown')
        .find('.w-dropdown-list')
        .fadeIn('fast')
        .css({ display: 'flex' });
    });

    $(document).on('click', function (e) {
      if (
        $(
          '.header-help-dropdown-container.bottom-side-positionned[style*="display: flex"]'
        ) &&
        !$(e.target).closest(
          '.header-help-dropdown-container.bottom-side-positionned .help-user-menu-dropdown-block'
        ).length
      ) {
        $('.header-help-dropdown-container.bottom-side-positionned').hide();
      }
    });
  };

  useEffect(() => {
    handleJQueryScripts();
  }, []);

  let containerStyle = {
    backgroundColor: 'inherit',
  };

  const extraStyles = {
    width: '100%',
    alignItems: 'start',
    paddingLeft: '15px',
    paddingTop: '15px',
  };

  if (sidebar) {
    containerStyle = { ...containerStyle, ...extraStyles };
  }

  return (
    <div
      className="w-layout-vflex left-sidebar-bottom-block all-whiten-in-dark-mode"
      style={containerStyle}
    >
      {auth?.tenant?.is_admin && instructorMode && (
        <Link
          className={classnames('w-inline-block', {
            'w--current': pathname.startsWith(analyticsUrl),
          })}
          to={analyticsUrl}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div
            className={classnames(
              'w-layout-hflex left-sidebar-bottom-btn greyish-bg-in-darkmode',
              { active: pathname.startsWith(analyticsUrl) }
            )}
            id="06c1888a-8d96-8883-4b16-0b16fe050ca3"
          >
            <img
              loading="lazy"
              src="/images/line-chart.svg"
              alt="Analytics"
              className="left-sidebar-bottom-btn-icon-2"
            />
            <div
              className="tooltip tooltip-left-sidebar-bottom-btn help"
              style={{ display: isHovered && !sidebar ? 'block' : 'none' }}
            >
              Analytics
            </div>
            <div className="left-sidebar-bottom-btn-label">Analytics</div>
          </div>
        </Link>
      )}
      {HELP_THREE_DOT_MENU_ENABLED && (
        <div className="w-layout-hflex header-action-btn-block info-header-action-btn-block bottom-side-positionned">
          <div
            data-hover="false"
            data-delay={0}
            className="header-help-dropdown w-dropdown"
          >
            <div
              title="Action menu"
              className="header-action-btn all-whiten-in-dark-mode greyish-bg-in-darkmode left-sidebar-bottom-btn w-dropdown-toggle has-custom-tooltip help-action-btn"
            >
              <img
                src="/images/more-vertical.svg"
                loading="lazy"
                alt="View All Menu Items By Clicking"
                className="ai-expert-header-action-icon"
              />
              <div className="left-sidebar-bottom-btn-label">
                {t('Support Hub')}
              </div>
              <div className="tooltip tooltip-left-sidebar-bottom-btn support-zone">
                {t('Support Hub')}
              </div>
            </div>
            <Helpmenudropdownbox
              style={{ display: 'none' }}
              leftSideBarBottomPositionned={true}
            />
          </div>
        </div>
      )}
      {/* <ThemeSwitcher /> */}
    </div>
  );
};

export default SidebarBottomBlock;
