export function queryParams(route, args) {
  let _args = { ...args };
  let url = route(_args);

  delete _args.org;
  delete _args.userId;

  const params = new URLSearchParams(_args);

  let order_by = '';
  if (params.has('order_by')) {
    const order_by_args = _args.order_by;

    if (Array.isArray(order_by_args)) {
      params.delete('order_by');

      order_by_args.forEach((value) => {
        const encodedValue = encodeURIComponent(value);
        order_by += `&order_by=${encodedValue}`;
      });
    }
  }

  url += `?${params.toString()}${order_by}`;

  return url;
}
