import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigationActions } from '../../lib/redux/navigation/slice';

export default function useMenus() {
  const settingsMenuRef = useRef();
  const userMenuRef = useRef();
  const helpMenuRef = useRef();
  const suggestedPromptRef = useRef();

  const dispatch = useDispatch();

  const nav = useSelector((state) => state.navigation?.data);

  const refs = {
    settings: settingsMenuRef,
    user: userMenuRef,
    help: helpMenuRef,
    suggestedprompt: suggestedPromptRef,
  };

  const handleClickOutsideMenu = useCallback(
    (event) => {
      const menus = Object.keys(refs);
      menus?.forEach((menu) => {
        const menuRef = refs[menu];
        if (menuRef?.current && nav?.menus[menu]) {
          if (!menuRef.current?.contains(event?.target)) {
            dispatch(
              navigationActions.navigationUpdated({
                ...nav,
                menus: { ...nav.menus, [menu]: false },
              })
            );
          }
        }
      });
    },
    [nav]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideMenu);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideMenu);
    };
  }, [nav]);

  return { refs };
}
