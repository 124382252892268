import React, { useEffect, useRef } from 'react';
import './PricingIframeBox.css';
import { useTriggers } from '../../hooks/navigation';
import { useTranslation } from 'react-i18next';
import { getTenant, getUserName, isJSON } from '@iblai/ibl-web-react-common';
import { toast } from 'react-hot-toast';
import { Error } from '../../modules/alerts';
const PricingIframeBox = (props) => {
  const { handleModalCloseClick, handleSeeAllMentorsClick } = useTriggers();

  const { t } = useTranslation();
  const PRICING_URL = process.env.REACT_APP_IBL_IFRAMED_PRICING_PAGE_URL;
  const pricingBoxIframe = useRef(null);

  useEffect(() => {
    window.addEventListener('message', handleIframePostMessageInteractions);
    return () => {
      window.removeEventListener(
        'message',
        handleIframePostMessageInteractions
      );
    };
  }, []);

  const getPaymentSuccessCallbackUrl = () => {
    return `${process.env.REACT_APP_IBL_SPA_AUTH_URL}/create-organization?redirect-to=${window.location.origin}`;
  };

  const handleIframePostMessageInteractions = (event) => {
    const message = isJSON(event?.data) ? JSON.parse(event.data) : null;
    if (message?.ready) {
      const dataToSend = {
        axd_token: localStorage.getItem('axd_token'),
        //tenantData: localStorage.getItem('current_tenant'),
        success_url: getPaymentSuccessCallbackUrl(),
        cancel_url: window.location.href,
        mode: 'subscription',
        metric_type: 'user_count',
        metered: false,
        //is_free_trial: true,
        endpointURL: `${process.env.REACT_APP_IBL_DM_URL}/api/service/orgs/main/users/${getUserName()}/stripe/checkout-session/`,
      };
      pricingBoxIframe.current.contentWindow.postMessage(
        JSON.stringify({ data: dataToSend }),
        '*'
      );
    }
    if (message?.payment_initialization_launched) {
      if (message?.payment_initialization_successful) {
        window.location.href = message?.redirect_to;
      } else {
        toast.custom(
          'An error occurred during the payment initialization process. Please try again'
        );
        handleSeeAllMentorsClick(false);
      }
    }
  };

  return (
    <div title={'External checkout modal'} className="modal pricing-iframe-box">
      <div className="w-layout-vflex modal-container mentor-list-modal-container">
        <div className="w-layout-vflex modal-body user-settings-modal-body">
          <div
            className="modal-close-wrapper"
            data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
            onClick={handleModalCloseClick}
            prop-events-value-onclick="handleModalCloseClick"
            title={'Close'}
          >
            <img alt="" loading="lazy" src="/images/close_1close.png" />
          </div>
          <iframe
            title={'PRICING SPA'}
            id="external-pricing-iframe-box"
            frameBorder={0}
            ref={pricingBoxIframe}
            src={PRICING_URL}
            className={'external-pricing-iframe-box'}
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default PricingIframeBox;
