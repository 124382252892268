import React, { useEffect, useRef } from 'react';

import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IBLSpinLoader } from '@iblai/ibl-web-react-common';

import useNewChat from '../../hooks/chat-room/useNewChat';
import Pinnedchatbox from '../Pinnedchatbox/Pinnedchatbox';
import Recentchatbox from '../Recentchatbox/Recentchatbox';
import { navigationActions } from '../../lib/redux/navigation/slice';
import usePinnedMessages from '../../hooks/api/messages/usePinnedMessages';
import useRecentMessages from '../../hooks/api/messages/useRecentMessages';

import './Sidebar.css';
import { isMobile } from '../../utils/shared';

const Sidebar = (props) => {
  const auth = useSelector((state) => state.auth.data);
  const ref = useRef();

  const { recentMessages, pinMessages, moreRecentMessages } = useSelector(
    (state) => state.chat
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { mode } = useSelector((state) => state.embed);
  const { data } = useSelector((state) => state.navigation);
  const mentors = useSelector((state) => state.mentors);
  const { handleShowMoreChatBoxBtnClick, moreMessagesLoading } =
    useRecentMessages();
  const pmessages = usePinnedMessages();

  const pmessagesToDisplay = pinMessages?.results?.filter(
    (item) => item?.messages?.length > 0
  );
  const rmessagesToDisplay = recentMessages?.results?.filter(
    (item) => item?.messages?.length > 0
  );

  const { handleNewChatBtnClick } = useNewChat();
  const { sidebar, mobileSidebar } = useSelector(
    (state) => state.navigation.data
  );
  const navigation = useSelector((state) => state.navigation.data);

  const platform = auth?.tenant?.key;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isMobile() &&
        sidebar &&
        ref.current &&
        !ref.current.contains(event.target)
      ) {
        dispatch(
          navigationActions.navigationUpdated({
            ...navigation,
            sidebar: false,
            mobileSidebar: false,
          })
        );
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [sidebar, navigation]);

  const _closeHistory = () => {
    dispatch(
      navigationActions.navigationUpdated({
        ...data,
        mobileSidebar: false,
      })
    );
  };

  return (
    <div
      ref={ref}
      className={`body-left-side all-whiten-in-dark-mode`}
      style={{
        display: sidebar || mobileSidebar ? 'flex' : 'none',
        paddingTop: mode === 'advanced' ? '10px' : '0',
        height: mode === 'advanced' ? '100%' : '88%',
        background:
          mode === 'advanced' ? 'var(--background)' : 'var(--very-light-blue)',
      }}
    >
      <div
        className="w-layout-vflex body-left-side-block"
        style={{ height: '100%' }}
      >
        {/* Explore Link (from second file) */}
        {mode !== 'advanced' && (
          <Link
            to={`/platform/${platform}/explore`}
            className="w-layout-hflex new-chat-btn-block all-whiten-in-dark-mode greyish-bg-in-darkmode light-grey-bg-dark-mode"
            behave-as-btn="1"
            prop-events-names="onClick"
            prop-events-value-onclick="handleNewChatBtnClick"
            title="Explore"
            tabIndex="101"
            role="button"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="icon-md"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.75 4.5C5.50736 4.5 4.5 5.50736 4.5 6.75C4.5 7.99264 5.50736 9 6.75 9C7.99264 9 9 7.99264 9 6.75C9 5.50736 7.99264 4.5 6.75 4.5ZM2.5 6.75C2.5 4.40279 4.40279 2.5 6.75 2.5C9.09721 2.5 11 4.40279 11 6.75C11 9.09721 9.09721 11 6.75 11C4.40279 11 2.5 9.09721 2.5 6.75Z"
                fill="currentColor"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.25 4.5C16.0074 4.5 15 5.50736 15 6.75C15 7.99264 16.0074 9 17.25 9C18.4926 9 19.5 7.99264 19.5 6.75C19.5 5.50736 18.4926 4.5 17.25 4.5ZM13 6.75C13 4.40279 14.9028 2.5 17.25 2.5C19.5972 2.5 21.5 4.40279 21.5 6.75C21.5 9.09721 19.5972 11 17.25 11C14.9028 11 13 9.09721 13 6.75Z"
                fill="currentColor"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.75 15C5.50736 15 4.5 16.0074 4.5 17.25C4.5 18.4926 5.50736 19.5 6.75 19.5C7.99264 19.5 9 18.4926 9 17.25C9 16.0074 7.99264 15 6.75 15ZM2.5 17.25C2.5 14.9028 4.40279 13 6.75 13C9.09721 13 11 14.9028 11 17.25C11 19.5972 9.09721 21.5 6.75 21.5C4.40279 21.5 2.5 19.5972 2.5 17.25Z"
                fill="currentColor"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.25 15C16.0074 15 15 16.0074 15 17.25C15 18.4926 16.0074 19.5 17.25 19.5C18.4926 19.5 19.5 18.4926 19.5 17.25C19.5 16.0074 18.4926 15 17.25 15ZM13 17.25C13 14.9028 14.9028 13 17.25 13C19.5972 13 21.5 14.9028 21.5 17.25C21.5 19.5972 19.5972 21.5 17.25 21.5C14.9028 21.5 13 19.5972 13 17.25Z"
                fill="currentColor"
              />
            </svg>
            <div className="text-block-32">{t('Explore')}</div>
          </Link>
        )}

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          {mode === 'advanced' && (
            <div
              className="w-layout-hflex flex-block-102"
              style={{
                height: 'unset',
                width: 'unset',
                marginLeft: '0',
                background: 'unset',
                alignItems: 'center',
              }}
            >
              <div
                data-w-id="1cd5727d-dbe4-90b7-1e8f-f310633067af"
                className="text-block-111"
                onClick={_closeHistory}
              >
                arrow_back_ios
              </div>
              <div
                className="recents-title-2"
                style={{ color: 'var(--bodyicon)' }}
              >
                Recent Activity
              </div>
            </div>
          )}

          {/* New Chat Button */}
          <div
            className={`w-layout-hflex new-chat-btn-block all-whiten-in-dark-mode greyish-bg-in-darkmode light-grey-bg-dark-mode ${mode === 'advanced' ? 'new-chat-advance-container' : ''}`}
            onClick={handleNewChatBtnClick}
            behave-as-btn="1"
            prop-events-names="onClick"
            prop-events-value-onclick="handleNewChatBtnClick"
            title="New Chat"
            tabIndex="101"
            role="button"
            style={mode === 'advanced' ? { width: 'unset' } : {}}
          >
            {mode === 'advanced' ? (
              <div className="text-block-111-copy new-chat-advance-image">
                add
              </div>
            ) : (
              <img
                alt="new chat"
                className="image-32"
                loading="lazy"
                src="/images/plus-4.svg"
              />
            )}
            <div
              className={`text-block-32 ${mode === 'advanced' ? 'new-chat-advance-text' : ''}`}
            >
              {t('New Chat')}
            </div>
          </div>
        </div>

        {/* History Container */}
        <div className="w-layout-vflex history-container">
          {pmessagesToDisplay?.length > 0 && (
            <div className="w-layout-vflex history-set-container">
              <h6 className="heading-8">{t('Pinned')}</h6>
              {pmessagesToDisplay.map((item) => (
                <Pinnedchatbox key={item.session_id} item={item} />
              ))}
            </div>
          )}

          {rmessagesToDisplay?.length > 0 && (
            <div className="w-layout-vflex history-set-container">
              <h6 className="heading-8">{t('Recent')}</h6>
              {rmessagesToDisplay
                ?.filter((msgToDisplay) => {
                  // In advanced mode we need to display the chat history, but because it is in an embed,
                  // we need to ensure that we only show recent messages for the same mentor to avoid switching
                  // the mentor when when the recent message is clicked (for an embed)
                  if (mode === 'advanced') {
                    return mentors?.mentor.name === msgToDisplay.mentor.name;
                  } else {
                    return true;
                  }
                })
                ?.map((item) => (
                  <Recentchatbox key={item.session_id} item={item} />
                ))}
            </div>
          )}

          <div
            className="w-layout-vflex history-set-container"
            style={{ paddingBottom: '30px' }}
          >
            {recentMessages?.next && (
              <div
                className="w-layout-hflex show-more-less-block show-more-btn greyish-bg-in-darkmode"
                data-w-id="121c52ef-943c-710b-2870-9f612f67ac1d"
                onClick={handleShowMoreChatBoxBtnClick}
                prop-events-value-onclick="handleShowMoreChatBoxBtnClick"
              >
                <img
                  alt=""
                  className="image-38"
                  loading="lazy"
                  src="/images/chevron-down.svg"
                />
                <div>{t('Show more')}</div>
              </div>
            )}

            {moreRecentMessages?.map((item) => (
              <Recentchatbox key={item.session_id} item={item} />
            ))}
          </div>
          {moreMessagesLoading && <IBLSpinLoader size={35} />}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
