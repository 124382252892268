import { BASE_API_URL } from '../../../utils/api';
import { toast } from 'react-hot-toast';
import { mentorIsIframe } from '../../../utils/shared';
import { api, handleLogout } from '@iblai/ibl-web-react-common';
import { Error } from '../../../modules/alerts';
import { notifyParentOnTokenExpiry } from '../../../utils/auth';

export default function useApi() {
  const _getHeaders = (isForm, token = null) => {
    const headers = {
      Authorization: `Token ${token || localStorage.getItem('axd_token')}`,
    };
    if (!isForm) {
      headers['Content-Type'] = 'application/json';
    }
    return headers;
  };

  const _handle401Response = () => {
    if (mentorIsIframe()) {
      notifyParentOnTokenExpiry();
    } else {
      localStorage.setItem(
        'redirect-path',
        `${window.location.pathname}${window.location.search}`
      );
      handleLogout();
    }
  };

  const _handleResponse = async (response) => {
    if (response.ok) {
      try {
        return { data: await response.json() };
      } catch (error) {
        return { data: 'Done' };
      }
    } else {
      const error = await response.text();
      if (response.status === 401 || response.status === 403) {
        if (api.iblutils.isTokenExpired()) {
          _handle401Response();
        } else {
          console.error(
            '401 Occurred, but token is not expired. Details: ',
            error
          );
          _handle401Response();
        }
        return;
      }
      return { error };
    }
  };

  const _postData = async (endpoint, method, data, isForm, token = null) => {
    const url = `${BASE_API_URL}${endpoint}`;
    try {
      const response = await fetch(url, {
        method: method,
        body: isForm ? data : JSON.stringify(data),
        headers: _getHeaders(isForm, token),
      });

      const responseData = await _handleResponse(response);
      if (responseData?.error) {
        console.error(
          `REQUEST_ERROR: ${responseData?.error}; URL: ${method} ${url}; REQUEST_DATA: ${JSON.stringify(data)}`
        );
      }
      return responseData;
    } catch (e) {
      toast.custom(Error('Could not make request.'));
    }
  };

  const post = async (endpoint, data, isForm = false, token = null) => {
    return _postData(endpoint, 'POST', data, isForm, token);
  };

  const put = async (endpoint, data, isForm = false) => {
    return _postData(endpoint, 'PUT', data, isForm);
  };

  const patch = async (endpoint, data, isForm = false) => {
    return _postData(endpoint, 'PATCH', data, isForm);
  };

  const remove = async (endpoint, data = {}, isForm = false, token = null) => {
    return _postData(endpoint, 'DELETE', data, isForm, token);
  };

  const get = async (endpoint, params, token = null) => {
    let url = `${BASE_API_URL}${endpoint}`;
    if (params) {
      url += `?${new URLSearchParams(params).toString()}`;
    }

    try {
      const response = await fetch(url, {
        headers: _getHeaders(false, token),
      });
      const responseData = await _handleResponse(response);
      if (responseData?.error) {
        console.error(
          `REQUEST_ERROR: ${JSON.stringify(responseData?.error)}; URL: GET ${url};`
        );
      }
      return responseData;
    } catch (e) {
      toast.custom(Error('Could not make request'));
    }
  };

  return { get, post, put, patch, remove };
}
