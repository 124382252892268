import { useCallback, useEffect, useState, useRef } from 'react';

import useGetCredentials from '../api/mentors/useGetCredentials';


const useOneDrivePicker = (
) => {
    const [onedriveAppId, setOnedriveAppId] = useState(null);
    const [fullDomain, setFullDomain] = useState(null);
    const { handleFetchCredentials } = useGetCredentials();

    const hasFetchedCredentials = useRef(false);
    useEffect(() => {
      const handleFetchAppId = async () => {
        if (!hasFetchedCredentials.current) {
          // Check if credentials have been fetched
          hasFetchedCredentials.current = true;
          try {
            const data = await handleFetchCredentials('onedrive', false);
            if (data?.length > 0) {
              setOnedriveAppId(data[0].value.appId);
            }
          } catch (e) {}
        }
      };
  
      handleFetchAppId();
    }, [handleFetchCredentials]);
    const getFullDomain = () => {
      if (fullDomain !== null) {
        return fullDomain;
      }
      const url = new URL(window.location.href);
      const protocol = url.protocol;
      const hostname = url.hostname;
      const port = url.port ? `:${url.port}` : '';
      const finalFullDomain = `${protocol}//${hostname}${port}`
      setFullDomain(finalFullDomain);
      return finalFullDomain;
    }
    const pickOneDriveFile = useCallback((handleSuccess, appId) => {
      const odOptions = {
        clientId: appId,
        action: "download",
        multiSelect: true,
        openInNewWindow: true,
        advanced: {
          redirectUri: getFullDomain(),
          // only show folders, images, word files, powerpoint files, excel files, txt, PDF, csv, HTML, XML
          filter: "folder,photo,.docx,.doc,.txt,.pdf,.csv,.ppt,.pptx,.xls,.xlsx,.html,.htm,.xml"
        },
        success: handleSuccess,
        cancel: function () { console.log(null); },
        error: function (e) { console.log(e); }
      };
      window.OneDrive.open(odOptions);
    }, [])
    return {pickOneDriveFile, onedriveAppId}
};

export default useOneDrivePicker;
