import React from 'react';
import './Userpromptbox.css';
import Markdown from '../../modules/markdown/Markdown';

const Userpromptbox = (props) => {
  return (
    <div className="user-prompt-text-container">
      <div className="user-prompt-text-block">
        <div
          className="w-layout-hflex edit-user-prompt-container"
          prop-events-value-onclick="handleUserPromptBoxEditBtnClick"
          style={{ display: 'none' }}
        >
          <img
            alt=""
            className="edit-user-prompt-icon whiten-in-dark-mode"
            loading="lazy"
            src="/images/edit.svg"
          />
        </div>
        <div
          className="user-prompt-text"
          prop-data-value-text="How can I create a successful business based on using Generative AI in Healthcare?"
          style={{ flexDirection: 'column', alignItems: 'start' }}
        >
          <Markdown>{props?.message?.content}</Markdown>
        </div>
      </div>
    </div>
  );
};

export default Userpromptbox;
