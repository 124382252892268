import React from 'react';
import './Suggestedpromptbox.css';
import { useMenus, useTriggers } from '../../hooks/navigation';
import { useSelector } from 'react-redux';
import useSuggestedPrompt from '../../hooks/chat-room/useSuggestedPrompt';
import { useTranslation } from 'react-i18next';
import usePreviewMode from '../../hooks/iframe/usePreviewMode';
import {
  DOCUMENTS_EXTENSIONS,
  removeHtmlTags,
  userIsOnTrial,
} from '../../utils/shared';
import useDocumentUpload from '../../hooks/chat-room/useDocumentUpload';

const Suggestedpromptbox = ({ isShare, handleRedirectToMentor }) => {
  const { handleSuggestedPromptBtnClick, handleOpenFreeTrialModal } =
    useTriggers();
  const { mode } = useSelector((state) => state.embed);
  const menus = useMenus();
  const navigation = useSelector((state) => state.navigation.data);
  const mentors = useSelector((state) => state.mentors);
  const embedPreview = usePreviewMode();
  const { handleSuggestedPromptSelection } = useSuggestedPrompt();
  const { handlePromptFileUploadBtnClick, file, uploading, handleFileChange } =
    useDocumentUpload();
  const { t } = useTranslation();

  const handleClick = () => {
    if (embedPreview) {
      return;
    }

    if (isShare) {
      handleRedirectToMentor();
      return;
    }
    handleSuggestedPromptBtnClick();
  };
  if (mode === 'advanced') {
    return (
      <div className="w-layout-hflex suggested-prompts-row">
        <div className="w-layout-vflex flex-block-97">
          <div className="w-layout-vflex flex-block-7">
            <div
              data-w-id="47fa3887-fe3b-835b-381d-3f37c231467e"
              className="w-layout-blockcontainer suggested-prompt-dropdown w-container"
            >
              <div
                data-delay="0"
                data-hover="false"
                className="dropdown w-dropdown"
              >
                <div
                  className="dropdown-toggle w-dropdown-toggle"
                  id="w-dropdown-toggle-6"
                  aria-controls="w-dropdown-list-6"
                  aria-haspopup="menu"
                  aria-expanded="false"
                  role="button"
                  tabIndex="0"
                  onClick={handleClick}
                >
                  <div className="text-block-20">&gt;_ Suggested Prompts</div>
                </div>
                {navigation?.menus?.['suggestedprompt'] && (
                  <nav
                    className={`dropdown-list w-dropdown-list ${navigation?.menus?.['suggestedprompt'] ? 'w--open' : ''}`}
                    id="w-dropdown-list-6"
                    aria-labelledby="w-dropdown-toggle-6"
                    onClick={() => handleSuggestedPromptBtnClick(false)}
                  >
                    <div
                      data-w-id="8d47bc8f-3f8e-e8ee-e396-862075586d8e"
                      className="prompts-background"
                    >
                      <div
                        className="w-layout-blockcontainer container-23 w-container"
                        style={{ left: '10px' }}
                      >
                        <div className="w-layout-vflex flex-block-23">
                          <div className="text-block-21">
                            <strong>Prompt Suggestions</strong>
                          </div>
                          {mentors?.mentor?.suggested_prompts?.map(
                            (item, i) => (
                              <div
                                key={`${i}-suggested-prompt`}
                                onClick={() =>
                                  handleSuggestedPromptSelection(
                                    removeHtmlTags(item?.prompt)
                                  )
                                }
                                className="promptsbuttons w-button"
                                tabIndex="0"
                              >
                                {removeHtmlTags(item?.prompt)}
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </nav>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className="w-layout-blockcontainer attachement-button w-container"
          onClick={
            userIsOnTrial()
              ? handleOpenFreeTrialModal
              : handlePromptFileUploadBtnClick
          }
        >
          <img
            src="https://cdn.prod.website-files.com/6625276c7305376ca8ddfd8a/663e253febc34b209f27b673_attachement.png"
            loading="lazy"
            width="18"
            height="18"
            alt=""
            className="darkattachment"
          />
          <img
            src="https://cdn.prod.website-files.com/6625276c7305376ca8ddfd8a/663e24b72cd977556e92c8f9_output-onlinepngtools.png"
            loading="lazy"
            width="18"
            height="18"
            alt=""
            className="lightattachment"
          />
          <div id="attachment-form" style={{ display: 'none' }}>
            <input
              name="file"
              onChange={handleFileChange}
              id="attachment-upload"
              className="attachment-upload"
              type="file"
              accept={DOCUMENTS_EXTENSIONS.join(',')}
            />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="w-dropdown" data-delay={0} data-hover="false">
      <div
        className="mentor-suggestion-prompt-dropdown-toggle w-dropdown-toggle"
        style={{ cursor: embedPreview ? 'not-allowed !important' : 'pointer' }}
        title={embedPreview ? 'Chat is disabled in preview mode' : ''}
        onClick={handleClick}
        prop-events-value-onclick="handleSuggestedPromptBtnClick"
      >
        <div className="w-layout-hflex mentor-footer-btn-block light-black-bg-dark-mode all-whiten-in-dark-mode">
          <div className="w-layout-hflex mentor-footer-btn prompt-selector-dropdown-toggle mentor-footer-action-btn-focused">
            <img
              alt=""
              className="mentor-footer-action-icon"
              loading="lazy"
              src="/images/terminal.svg"
            />
          </div>
          <div className="text-block-35 suggested-prompt-label whiten-in-dark-mode">
            <strong className="bold-text-2 whiten-in-dark-mode hide-suggested-text">
              {t('Suggested Prompts')}
            </strong>
          </div>
        </div>
      </div>
      {navigation?.menus?.['suggestedprompt'] && (
        <nav
          ref={menus?.refs?.suggestedprompt}
          className="dropdown-list-2 w-dropdown-list w--open"
        >
          <div className="w-layout-vflex copilot-suggestion-prompt-dropdown-block black-bg-dark-mode all-whiten-in-dark-mode">
            {mentors?.mentor?.suggested_prompts?.map((item, i) => (
              <div
                key={`${i}-suggested-prompt`}
                onClick={() =>
                  handleSuggestedPromptSelection(removeHtmlTags(item?.prompt))
                }
                className="copilot-suggestion-prompt-element"
              >
                {removeHtmlTags(item?.prompt)}
              </div>
            ))}

            {mentors?.mentor?.suggested_prompts?.length === 0 && (
              <div>
                <i>'{t('No suggested prompts')}</i>
              </div>
            )}
          </div>
        </nav>
      )}
    </div>
  );
};

export default Suggestedpromptbox;
