import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { mentorActions } from '../../lib/redux/mentors/slice';
import { llmOrder, userIsOnTrial } from '../../utils/shared';
import demoLLMData from '../../demos/llms.json';
import demoLLMProviderData from '../../demos/llmProviders.json';
import useApi from '../api/base/useApi';

export default function useLLMs() {
  const auth = useSelector((state) => state.auth.data);
  const mentors = useSelector((state) => state.mentors);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const api = useApi();
  const loadLLMs = async () => {
    const endpoint = `/api/ai-mentor/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/mentor-llms/`;
    setLoading(true);
    const response = await api.get(endpoint);
    if (response?.data) {
      const data = parseToModels(response.data);
      dispatch(mentorActions.llmsUpdated(data));
      dispatch(mentorActions.providersUpdated(response.data));
    }

    setLoading(false);
  };

  const parseToModels = (data) => {
    const models = [];
    data?.forEach((item) => {
      item?.chat_models?.forEach((model) => {
        models.push({
          ...item,
          model: model.llm_name,
          description: model.description,
          display_name: model.display_name,
          order: llmOrder[model.llm_name] || 1000,
          provider: item.name
        });
      });
    });

    models.sort((a, b) => a.order - b.order);

    return models;
  };

  useEffect(() => {
    if (userIsOnTrial()) {
      dispatch(mentorActions.llmsUpdated(demoLLMData));
      dispatch(mentorActions.providersUpdated(demoLLMProviderData));
    }
  }, []);

  useEffect(() => {
    if (!userIsOnTrial()) {
      loadLLMs();
    }
  }, []);

  return { loading, error, loadLLMs };
}
