import { useSelector } from 'react-redux';
import useApi from '../api/base/useApi';

export default function useUserMentor() {
  const auth = useSelector((state) => state.auth.data);
  const api = useApi();

  const getUserMostRecentMentors = async () => {
    const url = `/api/ai-mentor/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/recently-accessed-mentors/`;
    const response = await api.get(url);
    if (response?.data) {
      return response.data;
    }
  };

  return { getUserMostRecentMentors };
}
