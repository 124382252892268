import { useDispatch, useSelector } from 'react-redux';
import useApi from '../api/base/useApi';
import { useParams } from 'react-router-dom';
import { mentorActions } from '../../lib/redux/mentors/slice';
export default function useMentorUtils() {
  const mentors = useSelector((state) => state.mentors);
  const auth = useSelector((state) => state.auth.data);
  const api = useApi();
  const pathParams = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const dispatch = useDispatch();
  const loadMentorDetails = async (name, tenant) => {
    const url = `/api/ai-mentor/orgs/${tenant}/users/${auth?.user?.user_nicename}/mentors/${name}/public-settings/`;
    const response = await api.get(url);
    if (response?.data) {
      return {
        name: response.data.mentor,
        settings: response.data,
        unique_id: response.data.mentor_unique_id,
        slug: response.data.mentor_unique_id,
        metadata: response?.data.metadata,
        suggested_prompts: response?.data.suggested_prompts,
        proactive_prompt: response?.data.proactive_prompt,
      };
    }
  };
  const loadMentorDataByUniqueId = async (unique_id) => {
    const url = `/api/ai-mentor/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/mentors/${unique_id}/`;
    const response = await api.get(url, {});
    return response?.data;
  }

  const _getMentorFromLocalStore = (name) => {
    return mentors?.featured?.results?.find(
      (item) => item.name === name || item.unique_id === name
    );
  };

  const getMentor = async (name) => {
    const localMentor = _getMentorFromLocalStore(name);
    if (localMentor) {
      return localMentor;
    }
    const tenant =
      auth?.tenant?.key ||
      pathParams.platform ||
      urlParams.get('tenant') ||
      'main';
    return await loadMentorDetails(name, tenant);
  };

  const updateLocalMentorSettings = (mentor, settings) => {
    const updatedMentors = mentors?.mentors?.results?.map((item) => {
      if (item.unique_id === mentor.unique_id) {
        return {
          ...item,
          settings: settings,
        };
      }
      return item;
    });
    dispatch(
      mentorActions.mentorsUpdated({
        ...mentors.mentors,
        results: updatedMentors,
      })
    );
  };

  const updateCurrentEditMentor = (payload) => {
    dispatch(mentorActions.editMentorUpdated(payload));
    updateLocalMentor(payload);
  };

  const updateLocalMentor = (mentor) => {
    const newMentors = mentors?.mentors?.results?.map((item) => {
      if (item.unique_id === mentor.unique_id) {
        return mentor;
      } else {
        return item;
      }
    });
    dispatch(
      mentorActions.mentorsUpdated({ ...mentors?.mentors, results: newMentors })
    );
  };

  const setCurrentMentorLLM = (llmList) => {
    const llms = mentors?.llms || llmList;
    if (llms?.length > 0) {
      loadMentorDataByUniqueId(mentors?.mentor?.unique_id).then((data) => {

        const currentLLM = llms?.find(
          (item) => {
            return item.model === data?.llm_name && item.provider === data?.llm_provider
          }
        );
        if (currentLLM) {
          dispatch(mentorActions.currentMentorLLMUpdated(currentLLM));
          return;
        }
      })
    }
  };

  return {
    getMentor,
    setCurrentMentorLLM,
    updateLocalMentorSettings,
    updateCurrentEditMentor,
    updateLocalMentor,
  };
}
