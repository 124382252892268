import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDebounce } from '../../../hooks/useDebounce';
import SubmitBtn from '../../../components/SubmitBtn/SubmitBtn';
import { IBLSpinLoader, isJSON } from '@iblai/ibl-web-react-common';
import { navigationActions } from '../../../lib/redux/navigation/slice';
export default function Flow() {
  const LANGFLOW_BASE_URL = process.env.REACT_APP_IBL_LANGFLOW_APP_BASE_URL;
  const mentor = useSelector((state) => state.mentors.edit.mentor);
  const darkMode = useSelector((state) => state.navigation.darkMode);
  const langflowAppIframe = useRef(null);
  const [submitting, setSubmitting] = useState(false);
  const [iframeFullyLoaded, setIframeFullyLoaded] = useState(false);
  //const [iframeHasReloaded, setIframeHasReloaded] = useState(false);
  const LANGFLOW_APP_MAIN_SRC = `${LANGFLOW_BASE_URL}/main`;
  const LANGFLOW_APP_FLOW_SRC = `${LANGFLOW_BASE_URL}/flow/${mentor?.unique_id}`;

  useEffect(() => {
    window.addEventListener('message', handleIframePostMessageInteraction);
    return () => {
      window.removeEventListener('message', handleIframePostMessageInteraction);
    };
  }, []);

  const handleIframePostMessageInteraction = (event) => {
    const message = isJSON(event?.data) ? JSON.parse(event.data) : null;
    /*if (event?.data?.langflowSaved) {
      setSubmitting(false);
    }*/
    if (message?.request_auth) {
      const message = {
        axd_token: localStorage.getItem('axd_token'),
        tenant: localStorage.getItem('tenant'),
      };
      langflowAppIframe.current.contentWindow.postMessage(message, '*');
    }
    /*if (message?.requestRedirect) {
      langflowAppIframe.current.src = LANGFLOW_APP_FLOW_SRC;
    }*/
    /*if (message?.pageLoaded) {
      setTimeout(() => {
        setIframeFullyLoaded(true);
      }, 3000);
    }*/
  };

  const handleLangflowSave = () => {
    setSubmitting(true);
    const message = {
      triggerLangflowSave: true,
    };
    langflowAppIframe.current.contentWindow.postMessage(message, '*');
  };

  return (
    <>
      {!iframeFullyLoaded && (
        <div
          className={'langflow-app-overlay-block'}
          style={{ background: darkMode ? '#000' : '#fff' }}
        >
          <IBLSpinLoader size={90} color={darkMode ? '#FFF' : '#000'} />
        </div>
      )}
      <iframe
        title={'Langlow APP'}
        id="embed-mentor-langflow-app"
        frameBorder={0}
        ref={langflowAppIframe}
        src={LANGFLOW_APP_FLOW_SRC}
        onLoad={() => {
          setIframeFullyLoaded(true);
        }}
        //src={`${LANGFLOW_BASE_URL}/login`}
      ></iframe>
      {/*<div className="langflow-app-save-btn-container">
        <SubmitBtn
          saving={submitting}
          onClick={submitting ? () => {} : () => handleLangflowSave()}
          label={'Save'}
        />
      </div>*/}
    </>
  );
}
