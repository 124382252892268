import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { mentorsOptions } from './options';
import { isMobile, userIsAdmin } from '../../utils/shared';
import useNewChat from '../../hooks/chat-room/useNewChat';
import useMentors from '../../hooks/api/mentors/useMentors';

export const MentorDropdownMenu = () => {
  const navigate = useNavigate();
  const { handleNewChatBtnClick } = useNewChat();
  const auth = useSelector((state) => state.auth.data);
  const mentors = useSelector((state) => state.mentors);
  const { handleMentorItemSelectFromOptions } = useMentors();

  const platform = auth?.tenant?.key;
  const mentor = mentors?.mentor;
  const mentorSlug = mentors?.mentor?.slug;
  const baseUrl = `/platform/${platform}/${mentorSlug}`;
  let mentorOptionsToShow = [];

  if (!userIsAdmin()) {
    mentorOptionsToShow = mentorsOptions.filter(
      (item) => !['flow', 'analytics'].includes(item?.label?.toLowerCase())
    );
  } else {
    mentorOptionsToShow = mentorsOptions;
  }

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <div className="w-layout-hflex default-featured-mentor-label-block">
          <div className="show-retrieved-doc-column-button-copy w-embed">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 -960 960 960"
              width="24px"
              fill="#646464"
            >
              <path d="M480-440q-66 0-113-47t-47-113v-140q0-25 17.5-42.5T380-800q15 0 28.5 7t21.5 20q8-13 21.5-20t28.5-7q15 0 28.5 7t21.5 20q8-13 21.5-20t28.5-7q25 0 42.5 17.5T640-740v140q0 66-47 113t-113 47Zm0-80q33 0 56.5-23.5T560-600v-100H400v100q0 33 23.5 56.5T480-520ZM160-120v-112q0-34 17.5-62.5T224-338q62-31 126-46.5T480-400q66 0 130 15.5T736-338q29 15 46.5 43.5T800-232v112H160Zm80-80h480v-32q0-11-5.5-20T700-266q-54-27-109-40.5T480-320q-56 0-111 13.5T260-266q-9 5-14.5 14t-5.5 20v32Zm240 0Zm0-500Z"></path>
            </svg>
          </div>
          <div className="ai-expert-choose-label">{mentor?.name}</div>
          <img
            alt=""
            className="image-48"
            loading="lazy"
            src="/images/chevron-down-3.svg"
          />
        </div>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          id="w-dropdown-list-12"
          className="w-layout-vflex flex-block-465"
          sideOffset={5}
          style={{ zIndex: '99999' }}
          align={isMobile() && 'start'}
        >
          {mentorOptionsToShow.map((option) => (
            <DropdownMenu.Item
              className="edit-page-dropdown-link w-inline-block"
              key={option.label}
              onClick={(event) => {
                event.stopPropagation();

                if (option.link === '/new-chat') {
                  handleNewChatBtnClick();
                  return;
                }

                if (option.link !== '/analytics') {
                  handleMentorItemSelectFromOptions(mentor);
                }

                navigate(`${baseUrl}${option.link}`);
                return;
              }}
            >
              <div className="w-layout-hflex chat-dropdown-button">
                <div className="code-embed-21 w-embed">{option.icon}</div>
                <div>{option.label}</div>
              </div>
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
