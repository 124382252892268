import { useRef, useState } from 'react';

import { useSelector } from 'react-redux';
import useWebSocket, { ReadyState } from 'react-use-websocket';

import useTriggers from '../../../hooks/navigation/useTriggers';

const DEFAULT_IMAGE = '/images/1.jpg';

const WS_URL = `${process.env.REACT_APP_IBL_WEB_RTC_WEBSOCKET_ORIGIN}/ws/webrtc-gemini-live/`;

function createUUID() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4() + s4() + s4()}`;
}

export default function WebRTCChat() {
  const uuid = useRef(createUUID());
  const mediaRecorderRef = useRef(null);
  const remoteAudioRef = useRef(null);
  const localAudioStreamRef = useRef(null);
  const peerConnectionRef = useRef(null);
  // const [isMute, setIsMute] = useState(false);

  const { _handleUpdateModalStatus } = useTriggers();

  const [peerConnectionStatus, setPeerConnectionStatus] =
    useState('disconnected');
  const auth = useSelector((state) => state.auth.data);
  const mentors = useSelector((state) => state.mentors);
  const sessionId = useSelector((state) => state.chat.sessionId);

  const websocketData = {
    flow: {
      name: mentors?.mentor?.name,
      tenant: auth?.tenant?.key,
      username: auth?.user?.user_nicename,
      pathway: mentors?.mentor?.unique_id,
    },
    session_id: sessionId,
    token: localStorage.getItem('axd_token'),
  };

  const { getWebSocket, readyState } = useWebSocket(WS_URL, {
    share: false,

    shouldReconnect: () => true,

    onOpen: async () => {
      try {
        await startMicrophone();
        if (localAudioStreamRef.current) {
          const mediaRecorder = new MediaRecorder(localAudioStreamRef.current);
          mediaRecorderRef.current = mediaRecorder;

          mediaRecorder.ondataavailable = (event) => {
            const blob = new Blob([event.data], { type: 'audio/mpeg-3' });
            getWebSocket()?.send(blob);
          };
        }
      } catch (error) {
        errorHandler(error);
      }

      if (!peerConnectionRef.current) {
        initiatePeerConnection();
      }

      getWebSocket()?.send(JSON.stringify(websocketData));
    },

    onMessage: (event) => {
      handleMessageFromServer(event);
    },
  });

  async function initiatePeerConnection(isCaller = true) {
    const peerConnection = new RTCPeerConnection();
    peerConnectionRef.current = peerConnection;

    try {
      peerConnection.onicecandidate = gotIceCandidate;

      peerConnection.onconnectionstatechange = () => {
        setPeerConnectionStatus(peerConnection.connectionState);
      };

      peerConnection.ontrack = gotRemoteStream;

      if (localAudioStreamRef.current) {
        for (const track of localAudioStreamRef.current.getAudioTracks()) {
          peerConnection.addTrack(track, localAudioStreamRef.current);
        }
      }

      if (isCaller) {
        if (peerConnectionRef.current) {
          peerConnectionRef.current
            .createOffer()
            .then(createdDescription)
            .catch(errorHandler);
        }
      }
    } catch (error) {
      errorHandler(error);
    }
  }

  function gotIceCandidate(event) {
    try {
      if (event.candidate != null) {
        const candidate = event.candidate;

        getWebSocket()?.send(
          JSON.stringify({
            ice: {
              address: candidate.address,
              candidate: candidate.candidate,
              component: candidate.component,
              foundation: candidate.foundation,
              port: candidate.port,
              priority: candidate.priority,
              protocol: candidate.protocol,
              relatedAddress: candidate.relatedAddress,
              relatedPort: candidate.relatedPort,
              sdpMLineIndex: candidate.sdpMLineIndex,
              sdpMid: candidate.sdpMid,
              tcpType: candidate.tcpType,
              type: candidate.type,
              usernameFragment: candidate.usernameFragment,
            },
            uuid: uuid.current,
          })
        );
      }
    } catch (error) {
      errorHandler(error);
    }
  }

  function gotRemoteStream(event) {
    if (event.track.kind === 'audio') {
      remoteAudioRef.current.srcObject = event.streams[0];

      remoteAudioRef.current.onloadedmetadata = () => {
        remoteAudioRef.current?.play();
      };
    } else {
      console.log('processing video stream');
    }
  }

  function handleMessageFromServer(event) {
    const signal = JSON.parse(event.data);

    if (signal.uuid === uuid) {
      return;
    }

    if (signal.sdp) {
      if (!peerConnectionRef.current) initiatePeerConnection(false);

      if (peerConnectionRef.current) {
        peerConnectionRef.current
          .setRemoteDescription(new RTCSessionDescription(signal))
          .then(() => {
            if (signal.type === 'offer') {
              if (peerConnectionRef.current) {
                return peerConnectionRef.current
                  .createAnswer()
                  .then(createdDescription);
              }
            }
          })
          .catch(errorHandler);
      }
    } else if (signal.ice && signal.ice.candidate) {
      if (!peerConnectionRef.current) initiatePeerConnection(false);

      if (peerConnectionRef.current) {
        peerConnectionRef.current
          .addIceCandidate(new RTCIceCandidate(signal.ice))
          .catch(errorHandler);
      }
    }
  }

  function createdDescription(description) {
    if (peerConnectionRef.current) {
      peerConnectionRef.current
        .setLocalDescription(description)
        .then(() => {
          if (peerConnectionRef.current) {
            getWebSocket()?.send(
              JSON.stringify({
                sdp: peerConnectionRef.current.localDescription?.sdp,
                type: peerConnectionRef.current.localDescription?.type,
                uuid: uuid,
              })
            );
          }
        })
        .catch(errorHandler);
    }
  }

  async function startMicrophone() {
    try {
      const audioStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      });
      localAudioStreamRef.current = audioStream;

      // setIsMute(false);
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  }

  function stopMicrophone() {
    if (localAudioStreamRef.current !== null) {
      localAudioStreamRef.current.getTracks().forEach((track) => track.stop());

      localAudioStreamRef.current = null;

      // setIsMute(true);
    }
  }

  function errorHandler(error) {
    console.error(error);
  }

  async function handleEndCall() {
    if (getWebSocket()) {
      await getWebSocket().close();
    }
    stopMicrophone();
    _handleUpdateModalStatus('ai-audio-chat-modal', false);
  }

  const isReady = readyState === ReadyState.OPEN;
  const isConnected = peerConnectionStatus === 'connected';

  let profileImageUrl = mentors?.mentor?.settings?.profile_image;
  if (profileImageUrl && !profileImageUrl.includes('s3.amazonaws.com')) {
    profileImageUrl += `?username=${auth?.user?.user_nicename}&tenant=${auth?.tenant?.key}`;
  }

  let label = 'Connecting...';

  if (isReady && !isConnected) {
    label = 'Preparing...';
  }

  if (isConnected) {
    label = websocketData?.flow?.name;
  }

  return (
    <div
      className="modal ai-audio-chat-modal lighter-dark-bg"
      style={{ display: 'flex' }}
      onClick={() => {
        handleEndCall();
      }}
    >
      <audio ref={remoteAudioRef} />
      <div
        className="w-layout-vflex modal-container ai-audio-chat-modal-container"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="w-layout-vflex modal-body ai-audio-chat-modal-body">
          <div className="w-layout-vflex flex-block-74">
            <h4 className="audio-chat-welcome-text">{label}</h4>
            <div className="text-block-48" id="call-duration"></div>
          </div>
          <div className="w-layout-hflex audio-chat-mentor-img-container">
            <img
              src={profileImageUrl ?? DEFAULT_IMAGE}
              loading="lazy"
              alt=""
              className="audio-chat-mentor-image"
              onError={(e) => {
                e.target.src = DEFAULT_IMAGE;
              }}
            />
            <div className={`audio-circle-wave-container w-embed`}>
              <div className="circle delay1"></div>
              <div className="circle delay2"></div>
              <div className="circle delay3"></div>
              <div className="circle delay4"></div>
            </div>
          </div>
          <div className="w-layout-hflex audio-chat-animated-soundwave-container">
            <canvas
              style={{ backgroundColor: 'transparent' }}
              id="ai-voice-visualizer"
            ></canvas>
          </div>
          <div
            className="w-layout-hflex audio-chat-action-btn-container"
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div className="w-layout-hflex audio-chat-action-btn audio-chat-animated-icon">
              <img
                src="/images/phone-hangup-svgrepo-com.svg"
                loading="lazy"
                alt=""
                className="audio-chat-action-icon"
              />
              <img
                src="/images/Siri-Logo-Transparent-Free-PNG.png"
                loading="lazy"
                sizes="100vw"
                srcSet="images/Siri-Logo-Transparent-Free-PNG-p-500.png 500w, images/Siri-Logo-Transparent-Free-PNG-p-800.png 800w, images/Siri-Logo-Transparent-Free-PNG.png 820w"
                alt=""
                className="audio-chat-mentor-img"
              />
            </div>
            {/* <div className="w-layout-hflex audio-chat-action-btn audio-chat-record-stop-action-btn">
              <div
                className="w-layout-hflex audio-chat-action-btn-icon-container"
                onClick={() => {
                  if (isConnected) {
                    if (isMute) {
                      startMicrophone();
                      return;
                    }
                    if (!isMute) {
                      stopMicrophone();
                      return;
                    }
                  }
                }}
              >
                <img
                  src={`/images/${isMute ? 'stop-svgrepo-com.svg' : 'microphone-svgrepo-com-1.svg'}`}
                  loading="lazy"
                  alt=""
                  className={`audio-chat-action-icon display-block smaller record-btn`}
                />
              </div>
            </div> */}
            <div
              onClick={() => {
                handleEndCall();
              }}
              className="w-layout-hflex audio-chat-action-btn audio-chat-hang-action-btn"
            >
              <img
                src="/images/phone-hangup-svgrepo-com.svg"
                loading="lazy"
                alt=""
                className="audio-chat-action-icon display-block"
              />
            </div>
          </div>
          <div className="html-embed w-embed">
            <ul className="audio-wave">
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
