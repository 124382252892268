import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import useUpdateMentorSettings from './useUpdateMentorSettings';
import { mentorActions } from '../../../lib/redux/mentors/slice';
import useApi from '../base/useApi';

export default function useEditMentor() {
  const auth = useSelector((state) => state.auth.data);
  const editMentor = useSelector((state) => state.mentors.edit);
  const mentors = useSelector((state) => state.mentors);
  const mentorCategories = useSelector((state) => state.mentors.categories);
  let profileImageUrl = editMentor?.mentor?.settings?.profile_image;
  if (profileImageUrl && !profileImageUrl.includes('s3.amazonaws.com')) {
    profileImageUrl += `?username=${auth?.user?.user_nicename}&tenant=${auth?.tenant?.key}`;
  }
  const [mentor, setMentor] = useState(editMentor.mentor);
  const [saving, setSaving] = useState(false);
  const [mentorImgSrc, setMentorImgSrc] = useState(profileImageUrl);
  const { handleUpdateSettings } = useUpdateMentorSettings();

  const dispatch = useDispatch();

  const api = useApi();

  useEffect(() => {
    setMentor(editMentor.mentor);
  }, [editMentor]);

  const handleUpdateLocalMentor = (record) => {
    const allMentors = mentors?.mentors?.results?.map((item) => {
      if (item.name === record.name) {
        return record;
      }
      return item;
    });

    dispatch(
      mentorActions.mentorsUpdated({ ...mentors.mentors, results: allMentors })
    );
    dispatch(
      mentorActions.editMentorUpdated({
        ...editMentor.mentor,
        allow_anonymous: record.allow_anonymous,
      })
    );
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'category') {
      setMentor((prevState) => {
        return {
          ...prevState,
          metadata: {
            ...prevState?.metadata,
            [name]: value,
          },
        };
      });
    } else {
      setMentor((prevState) => {
        return { ...prevState, [name]: value };
      });
    }
  };

  const handleTriggerFileUpload = () => {
    document.getElementById('input-mentor-image-upload').click();
  };

  const handleRemoveImage = () => {
    const imageInput = document.getElementById('input-mentor-image-upload');
    if (imageInput) {
      imageInput.value = '';
    }
    setMentorImgSrc(null);
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files?.length > 0) {
      const mentorImgSrc = URL.createObjectURL(files[0]);
      setMentorImgSrc(mentorImgSrc);
    } else {
      setMentorImgSrc(null);
    }
  };

  const handleSubmit = async (event) => {
    // Update the profile image if present
    const form = document.createElement('form');
    const profile_image = document.getElementById('input-mentor-image-upload');
    // return;
    setSaving(true);
    if (profile_image?.files?.length > 0) {
      form.appendChild(profile_image);
      const formData = new FormData(form);
      await handleUpdateSettings(mentor, formData, false, true);
    }
    let metadata = mentor?.metadata || {};
    try {
      metadata = JSON.parse(metadata);
    } catch {}

    const payload = {
      mentor_description: mentor.description,
      featured: mentor.featured,
      allow_anonymous: mentor?.allow_anonymous,
      metadata,
      mentor_visibility: mentor.featured
        ? 'viewable_by_tenant_students'
        : 'viewable_by_tenant_admins',
    };
    const settings = await handleUpdateSettings(mentor, payload);
    const newMentor = {
      ...mentor,
      metadata: { ...(metadata ?? {}), ...(settings?.metadata ?? {}) },
      settings,
    };
    handleUpdateLocalMentor(newMentor);
    setSaving(false);
  };

  const handleCheckChange = (event) => {
    const { name, checked } = event.target;
    setMentor((prevState) => {
      return { ...prevState, [name]: checked };
    });
  };

  return {
    mentor,
    handleChange,
    handleSubmit,
    handleCheckChange,
    saving,
    handleFileChange,
    mentorImgSrc,
    handleTriggerFileUpload,
    handleRemoveImage,
    mentorCategories,
  };
}
