import { useSelector } from 'react-redux';

export default function GeneratingResponse() {
  const mentors = useSelector((state) => state.mentors);
  return (
    <div className="ai-response-container ai-prompt-response-container light-black-bg-dark-mode learn-more-container">
      <div className="w-layout-hflex ai-response-container-content">
        <div className="ai-gif-container">
          <img
            src={
              (mentors?.mentor?.settings?.profile_image ||
                mentors?.mentor?.profile_image) ??
              '/images/ai-academy-p-500.png'
            }
            loading="lazy"
            style={{ borderRadius: '50%' }}
            alt=""
            className="image-3"
          />
        </div>
        <div
          className="ai-response-text-container whiten-in-dark-mode generating-result"
          style={{ fontSize: '15px' }}
        ></div>
      </div>
    </div>
  );
}
