const defaultImage = '/images/1-p-130x130q80.jpg';

export function MentorListCard({ mentor, onClick }) {
  return (
    <a
      href="#"
      data-target="safety"
      className="edit-page-dropdown-link edit-mentor-modal-trigger w-inline-block"
      onClick={(e) => onClick(e, mentor)}
    >
      <div className="w-layout-hflex chat-dropdown-button-2">
        <div className="w-layout-hflex flex-block-483">
          <img
            src={mentor.profile_image || defaultImage}
            loading="lazy"
            alt={mentor.name}
            className="image-181"
            onError={(e) => {
              e.target.src = defaultImage;
            }}
          />
        </div>
        <div className="w-layout-vflex mentor-data-column">
          <div>{mentor.name}</div>
          <div className="mentor-description">{mentor.description}</div>
        </div>
      </div>
    </a>
  );
}
